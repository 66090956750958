







import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
export default Vue.extend({
    data() {
        return {
            loading: false,
            type: '',
            id: '',
            msg: '',
        }
    },
    created() {
        this.type = this.$route.params.type
        this.id = this.$route.params.id
    },
    mounted() {
        this.getData()
    },
    methods: {

        getData() {
            this.loading = true
            if('msg' === this.type) {
                request.get(`/api/respond/rest_pm/${this.id}`).then((res: any) => {
                    this.msg = res.content
                    this.loading = false
                })
            }
        },

    },
    filters: {
        dtFormat(timestamp: number) {
            return moment(timestamp).format('YYYY/M/D HH:mm:ss')
        }
    }
})
